<template>
<RouterLink :to="orderlink(link)">
   <div class="card invoice-card">
      <div class="invoice-card__item">
        <div class="icon"><invoiceIcon /></div>
        <p>Invoice #{{number}}</p>
        </div>  
        <div class="invoice-card__item">
        <p>Date: {{date}}</p>
        </div> 
        <div class="invoice-card__item">
        <span class="badge badge-success">£{{total}}</span>
        </div> 
        <div class="invoice-card__item">
            <button type="button" class="btn mb-1 mr-1 btn-outline-primary"> Comments <span class="badge ml-2 badge-primary">4</span></button>
        </div>  
    </div>
</RouterLink>
  </template>
  <script>
import { RouterLink } from 'vue-router'
import invoiceIcon from './icons/invoice'

  export default {
    name: "card",
    components: { RouterLink, invoiceIcon },
    props: {
        className: { type: String, default: "" },
        bodyClass: { type: String, default: "" },
        headerClass: { type: String, default: "" },
        footerClass: { type: String, default: "" },
        number: { type: String, default: "" },
        date: { type: String, default: "" },
        link: { type: String, default: "" },
        total: { type: String, default: "" },
    },
    mounted() {
    },
    computed: {
        hasHeaderTitleSlot() {
            return !!this.$slots.headerTitle;
        },
        hasHeaderActionSlot() {
            return !!this.$slots.headerAction;
        },
        hasBodySlot() {
            return !!this.$slots.body;
        },
        hasFooterSlot() {
            return !!this.$slots.footer;
        }
    }
}
  </script>
  <style scoped lang="scss">
  .invoice-card{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    padding: 20px;
    &__item{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    p{
        margin: 0;
    }
    &:first-child{
    justify-content: flex-start;
    }
    .icon{
      background: #25c3cb;
      width: 50px;
      height: 50px;
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      svg{
        width: 30px;
        height: 30px;
        fill: #fff !important;
      }
    }
    }
  }
</style>